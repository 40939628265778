<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="currentPage === 'default'" class="pa-0">
      <v-sheet
        ref="sheet"
        color="accent"
        class="pa-2"
        :class="{
          'v-sheet__desktop': $voicer.isDesktopOrTablet,
          'ma-3 mb-4': $voicer.isDesktopOrTablet,
        }"
      >
        <div class="pl-2 pr-2 pt-1 white--text">
          Laissez-nous un message vocal en utilisant l'enregistreur ci-dessous.
          Racontez vos histoires ou partagez une expérience.
          <br />
          Apm c'est avant tout votre radio !
        </div>
        <v-btn color="primary" @click.stop="showFeedbackModal = true">
          Enregistrez votre message !
        </v-btn>
        <w-feedback-popup id="firstFeedback" v-model="showFeedbackModal" />
      </v-sheet>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import WFeedbackPopup from '../components/WFeedbackPopup'

export default {
  components: {
    WFeedbackPopup,
  },
  data() {
    return {
      showFeedbackModal: false,
    }
  },
  computed: {
    ...mapGetters({
      currentPage: 'root/currentPage',
    }),
  },

  methods: {
    handleOpen(e) {
      this.$store.dispatch('player/changePlayerContent', {
        content: this.$spoke.document('63fcb12f06470e7729aebfa1').lean(),
        noRedirect: false,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.w-block__pre-heading {
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 500;

  .v-sheet {
    border-radius: 8px;
  }

  .v-btn {
    border-radius: 4px;
  }
}

@media screen and (max-width: 600px) {
  .message_welcome_sir {
    flex-direction: column;
    align-items: center;
    div {
      margin-top: 15px;
    }
  }
}
</style>
