/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
module.exports = {
    useShell: [
        'bb-default',
        {
          mode: 'universes',
          universes: [
            {
              type: 'tag',
              tagId: '6618f122cba11aa991072c60',
              i18n: {
                fr: {
                  title: "Le Quart d'Heure Dirigeant",
                  description: `Retrouvez toutes les rubriques de votre podcast à la demande`,
                },
              },
              heading: {
                color: '#4AABD5',
                canRadio: false,
                dark: true,
                buttonColor: 'white',
                buttonLight: true,
              },
            },
            {
              type: 'tag',
              tagId: '65606b64842dab201e6b03bc',
              i18n: {
                fr: {
                  title: "Le Quart d'Heure Expert",
                  description: `Retrouvez toutes les rubriques de votre podcast à la demande`,
                },
              },
              heading: {
                color: '#533EFD',
                canRadio: false,
                dark: true,
                buttonColor: 'white',
                buttonLight: true,
              },
            },
            {
              type: 'tag',
              tagId: '6618e907cba11a965e072bb1',
              i18n: {
                fr: {
                  title: "Le Répondeur",
                  description: `Retrouvez toutes les rubriques de votre podcast à la demande`,
                },
              },
              heading: {
                color: '#FEB68D',
                canRadio: false,
                dark: true,
                buttonColor: 'white',
                buttonLight: true,
              },
            },
            {
              type: 'tag',
              tagId: '65606b59842dab7d9d6b03b6',
              i18n: {
                fr: {
                  title: 'Flash Spécial',
                  description: `Retrouvez toutes les rubriques de votre podcast à la demande`,
                },
              },
              heading: {
                color: '#FCDBAE',
                canRadio: false,
                dark: true,
                buttonColor: 'white',
                buttonLight: true,
              },
            },
            {
              type: 'tag',
              tagId: '6618f1c3cba11a6bcf072c66',
              i18n: {
                fr: {
                  title: 'Edition Spéciale',
                  description: `Retrouvez toutes les rubriques de votre podcast à la demande`,
                },
              },
              heading: {
                color: '#F86264',
                canRadio: false,
                dark: true,
                buttonColor: 'white',
                buttonLight: true,
              },
            },
            {
              type: 'tag',
              tagId: '6511726e213a2f02218cb9e3',
              i18n: {
                fr: {
                  title: 'Émissions Intégrales',
                  description: `Retrouvez toutes les rubriques de votre podcast à la demande`,
                },
              },
              heading: {
                color: '#FC6F79',
                canRadio: false,
                dark: true,
                buttonColor: 'white',
                buttonLight: true,
              },
            },
          ],
        },
      ],
    }
    