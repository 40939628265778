/**
 * configuration applied when BROCOLI_API_MODE = production
 */
module.exports = {
    useShell: [
      'bb-default',
      {
        mode: 'universes',
        universes: [
          {
            type: 'tag',
            tagId: '661e9481b661797a7b46f884',
            i18n: {
              fr: {
                title: "Le Quart d'Heure Dirigeant",
                description: `Retrouvez toutes les rubriques de votre podcast à la demande`,
              },
            },
            heading: {
              color: '#4AABD5',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '653a7092a49863c684ecda22',
            i18n: {
              fr: {
                title: "Le Quart d'Heure Expert",
                description: `Retrouvez toutes les rubriques de votre podcast à la demande`,
              },
            },
            heading: {
              color: '#533EFD',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '6617ea40156b45439eb96a4e',
            i18n: {
              fr: {
                title: "Le Répondeur",
                description: `24h/24, retrouvez tous les messages de l’Apm`,
              },
            },
            heading: {
              color: '#FEB68D',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '661e9494156b45699cb9799f',
            i18n: {
              fr: {
                title: 'Flash Spécial',
                description: `Retrouvez toutes les rubriques de votre podcast à la demande`,
              },
            },
            heading: {
              color: '#FCDBAE',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '664c66760425c736681544f3',
            i18n: {
              fr: {
                title: 'Émissions Intégrales',
                description: `Retrouvez toutes les émissions à la demande`,
              },
            },
            heading: {
              color: '#FC6F79',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
        ],
      },
    ],
  }
